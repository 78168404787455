import * as Linking from 'expo-linking'
import * as React from 'react';
import { View, Button, Text, ActivityIndicator } from 'react-native';
import {setDoc, addDoc, collection} from 'firebase/firestore'

import Containers from '../components/StyledContainers';
import { AppLink, AppText, Content, ActionLink } from '../components/StyledText';
import { ActionButton, AppTextInput } from '../components/FormComponents';
import { fireStore } from '../firebase';

export default function TabTwoScreen() {

  const [emailAddress, setEmailAddress] = React.useState("")
  const [website, setWebsite] = React.useState("")
  const [error, setEmailError] = React.useState("")
  const [submitted, setSubmittedMessage] = React.useState("")
  const [working, setWorking] = React.useState(false)


  const validateEmail = () => {
    setSubmittedMessage("")
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(emailAddress) === false) {
      setEmailError("Please type a valid email address");
    } else {
      setEmailError("");
    } 
  };


  const submitToFirebase = async () => {
    validateEmail()
    if (error === "") {
      try {
        setWorking(true)
        await addDoc(collection(fireStore, 'subscribers'), {
          emailAddress: emailAddress,
          website: website,
        })
        setWorking(false)
      } catch {
        console.log("couldn't save")
      } finally {
        setSubmittedMessage("Thanks so much! And speak soon")
        setEmailAddress("")
        setWebsite("")
        setEmailError("")
      }
    }
  }



  const mailNkatha = () => {
    Linking.openURL('mailto:clientservices@yakutti.com')
  }
  
  return (
    <View style={Containers.screenFrame}>
      <AppText style={{ textAlign: 'center', paddingTop: 36 }}>
          How do we travel well?
          We are working towards answering this question. Reach out if you are working on answering this question too!
        </AppText>
      <View style={{ paddingVertical: 16, textAlign: 'center', alignItems:'center'}}>
          <ActionLink onPress={mailNkatha}>
            clientservices@yakutti.com
          </ActionLink>
        </View>
      <AppText style={{ textAlign: 'center' }}>
        Or leave your email address and we will be in touch to let you know what we are finding out.
      </AppText>
      <View style={Containers.formBox}>
      <View style={Containers.formInputBox}>
          <View style={{marginBottom:7}}>
            <AppTextInput
            label="Email address"
            keyboardType="email-address"
            returnKeyType={"next"}
            value={emailAddress}
            autoCapitalize="none"
            autoCorrect={false}
            onEndEditing={validateEmail}
            onErrorCorrect={validateEmail}
            error={error}
            onChangeText={setEmailAddress}
          />
          </View>
        <AppTextInput
          label="Name/link to website/social media handle (optional)"
          returnKeyType={"done"}
          value={website}
          onChangeText={setWebsite}
          />
          <View style={{ paddingVertical: 7 }}>
            {working && <ActivityIndicator size='small' color="grey" />}
          </View>
          <ActionButton style={{ alignSelf: 'center' }} title="Submit" onPress={submitToFirebase} />
          <Text style={{ alignSelf: 'center' }}>
            {submitted && <Text style={Content.feedbackText}>{submitted}</Text>}
          </Text>
        </View>
      </View>
    </View>
  )
}