import React from 'react'
import { StyleSheet, hairlineWidth, Platform } from 'react-native'
import { Dimensions } from "react-native";
import Constants from 'expo-constants'
import useColorScheme from '../hooks/useColorScheme';
import Colors from '../constants/Colors'
import { useSafeAreaInsets } from 'react-native-safe-area-context';


const Containers = StyleSheet.create({
    screenFrame: {
        width: '100%',
        flex: 1,
        backgroundColor: '#fff',
        padding: 11,
        alignSelf:'center'
    },
    horizontalScreenFrame: {
        width: '100%',
        flex: 1,
        backgroundColor: '#fff',
        padding: 11,
        flexDirection:'row'
    },
    contentCard: {
        alignSelf: 'center',
        marginTop: 7,
        justifyContent:'space-around',
        alignItems: 'center',
        backgroundColor:'white',
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowRadius: 1,
    },
    contentCardWithShadow: {
        width: '99%',
        marginTop: 5,
        paddingBottom: 14,
        shadowColor: "#000",
        shadowOpacity: 0.4,
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowRadius: 1,
        backgroundColor: '#fff',
    },
    containedCard: {
        paddingHorizontal: 15,
        borderRadius: 2,
        paddingVertical: 17,
        shadowColor: "#000",
        shadowOpacity: 0.15,
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowRadius: 1,
        elevation: 1,
        backgroundColor: '#fff',
    },
    senderMessageCard: {
        marginHorizontal: 18,
        marginVertical: 14,
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOpacity: 0.15,
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowRadius: 1,
        elevation: 1,
        backgroundColor: '#fff',
    },
    shadow: {
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 1,
            height: 0
        },
        shadowRadius: 1,
    },
    actionCard: {
        width: Dimensions.get('window').width - 24,
        paddingHorizontal: 7,
        paddingVertical: 11,
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderColor: "rgba(204,201,207, 1)",
        justifyContent: 'center',
    },
    actionCardTopBorder: {
        width: Dimensions.get('window').width - 24,
        paddingHorizontal: 7,
        paddingVertical: 11,
        borderStyle: "solid",
        borderTopWidth: 1,
        borderColor: "rgba(204,201,207, 1)",
        justifyContent: 'center',
    },
    smallCard: {
        borderColor: "rgba(204,201,207, 1)",
        borderBottomWidth: 0,
        borderWidth: 1,
        borderRadius: 2,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 0,
        elevation: 2,
        borderRadius: 5,
        paddingHorizontal: 14,
        paddingVertical: 14,
        alignItems: 'center',
        margin: 14,
    },
    fullActionCard: {
        width: Dimensions.get('window').width,
        paddingHorizontal: 7,
        paddingVertical: 7,
        borderStyle: "solid",
        borderBottomWidth: 0.4,
        borderTopWidth: 0.7,
        borderColor: "rgba(204,201,207, 1)",
        justifyContent: 'center',
    },
    innerLeftAlignContent: {
        paddingVertical: 16,
        paddingHorizontal: 12,
    },
    verticalPadding: {
        paddingVertical: 16,
    },
    innerLeftAlignList: {
        paddingVertical: 9,
        paddingHorizontal: 14,
    },
    innerCenterAlignContent: {
        paddingVertical: 16,
        paddingHorizontal: 12,
        alignItems: 'center',
        width: '100%',
        maxWidth: Dimensions.get('window').width,
    },
    horizontalFlex: {
        width: Dimensions.get('window').width,
        paddingHorizontal: 12,
        paddingVertical: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    horizontalFlexStartWide: {
        width: Dimensions.get('window').width,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    evenHorizontal: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
    },
    textPadding: {
        paddingVertical: 14,
        paddingHorizontal: 7,
    },
    centerFlex: {
        marginVertical: 14,
        alignItems: 'center',
    },
    leftFlex: {
        paddingVertical: 7,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    horizontalCenterFlex: {
        paddingVertical: 14,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    horizontalWrap: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    rightFlex: {
        height: 30,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    formBox: {
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-around',
        paddingHorizontal: 24,
        width:'60%',
    },
    formInputBox: {
        marginTop:60,
        alignSelf: 'stretch',
    },
    tableContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableCellHalf: {
        width: '48%',
    },
    tableCellThird: {
        width: '30%',
    },
    tableCellTwoThirds: {
        width: '64%',
    },
    tableCellFourth: {
        width: '24%',
    },
    tableCellThreeFourths: {
        width: '72%',
    },
    textInputStyle: {
        borderStyle: "solid",
        width: '95%',
        borderWidth: 1,
        borderRadius: 1,
        borderColor: "#707070",
        paddingLeft: 8,
        paddingVertical: 3,
        alignItems: 'center',
        marginTop: 3,
        fontSize: 15,
        color: '#000000',
        fontFamily: 'Jost-Regular',
    },
    placeInput: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 1,
        width: '40%',
        borderColor: "#707070",
        paddingLeft: 8,
        paddingVertical: 3,
        alignItems: 'center',
        marginTop: 3,
        fontSize: 15,
        color: '#000000',
        fontFamily: 'Jost-Regular',
    },
    messageInputStyle: {
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 5,
        borderColor: "#707070",
        paddingLeft: 8,
        paddingVertical: 3,
        alignItems: 'center',
        marginTop: 3,
        fontSize: 15,
        color: '#000000',
        fontFamily: 'Jost-Regular',
        backgroundColor: '#fff',
    },
    inactivebordercolor: {
        borderColor: "#707070",
    },
    errorbordercolor: {
        borderColor: Colors.errorText,
    },
    activebordercolor: {
        borderColor: "#000000",
    },
    inactiveSelectionBox: {
        borderWidth: 1,
        borderColor: '#707070',
        color: '#707070',
        paddingHorizontal: 26,
        marginVertical: 3,
        paddingVertical: 5,
        marginRight: 18,
        fontFamily: 'Jost-Regular',
    },
    activeSelectionBox: {
        borderWidth: 1,
        borderColor: '#000',
        backgroundColor: '#000',
        color: '#ffffff',
        paddingHorizontal: 26,
        marginVertical: 3,
        paddingVertical: 5,
        marginRight: 18,
        fontFamily: 'Jost-Regular',
    },
    inactiveSelectionTab: {
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#000000',
        color: '#000000',
        paddingHorizontal: 27,
        marginVertical: 5,
        paddingVertical: 5,
        marginRight: 18,
        fontFamily: 'Jost-Regular',
        fontSize: 15,
    },
    activeSelectionTab: {
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#000',
        backgroundColor: '#000',
        color: '#f1f1f1',
        paddingHorizontal: 27,
        paddingVertical: 5,
        marginRight: 18,
        marginVertical: 5,
        fontFamily: 'Jost-Regular',
        fontSize: 15,
    },
    fullModalContainer: {
        flex: 1,
        paddingTop: Constants.statusBarHeight,
        height: Dimensions.get('window').height,
        justifyContent: 'space-between',
    },
    contentModalContainer: {
        flex: 1,
        paddingTop: Constants.statusBarHeight,
        height: Dimensions.get('window').height,
        justifyContent: 'flex-start',
    },
    appBottomBar: {
        width: Dimensions.get('window').width,
        backgroundColor: '#fff',
        shadowColor: "rgba(204,201,207, 1)",
        height: 83,
        shadowOffset: {
            width: 0,
            height: -1
        },
        shadowRadius: 0,
        shadowOpacity: 1,
    },
    appHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff'
    },
    touchable: {
        height: 44,
        width: 44,
    },
    sectionSeparation: {
        borderTopWidth: 0.8,
        borderTopColor: 'rgba(204,201,207, 10)',
        width: '100%',
        height: 3,
    },
    topBorder: {
        alignItems: 'stretch',
        borderTopWidth: 0.8,
        borderStyle: 'solid',
        borderTopColor: 'rgba(204,201,207,10)',
    },
    bottomBorder: {
        borderBottomWidth: 0.8,
        borderStyle: 'solid',
        borderBottomColor: 'rgba(204,201,207,10)',
        alignSelf: 'stretch',
        paddingVertical: 3,
    },
    feedFooter: {
        width: '100%',
        paddingHorizontal: 14,
        paddingTop: 3,
        paddingBottom: 3,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    halfModal: {
        backgroundColor: '#fff',
        shadowColor: "rgba(204,201,207, 1)",
        shadowOffset: {
            width: 0,
            height: -1
        },
        shadowRadius: 0,
        shadowOpacity: 1,
    },
    inputCell: {
        flex: 0,
        flexShrink: 1,
        paddingHorizontal: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 2,
        borderColor: Colors.tertiaryMetaColor
    },
    bottomShadow: {
        shadowColor: "rgba(204,201,207, 1)",
        shadowOffset: {
            width: 0,
            height: 1
        },
        shadowRadius: 0,
        shadowOpacity: 1,
    }
})


export default Containers
