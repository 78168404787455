/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { SimpleLineIcons } from '@expo/vector-icons';
import { createDrawerNavigator, DrawerItemList } from '@react-navigation/drawer';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { AppHeader, RightButton } from '../components/ContentComponents';
import Containers from '../components/StyledContainers';
import { Content } from '../components/StyledText';
import Colors from '../constants/Colors';
import { ScreenHeaderText } from '../components/StyledText';
import TabOneScreen from '../screens/TabOneScreen';
import TabTwoScreen from '../screens/TabTwoScreen';

export default function Navigation() {
  return (
    <NavigationContainer>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={DrawerNavigator} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}


/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 * 
 */

function CustomDrawer(props) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={Containers.innerLeftAlignContent}>
        <Pressable onPress={() => props.navigation.closeDrawer()}>
          <SimpleLineIcons name="close" size={28} color={Colors.actionColor} />
        </Pressable>
        <DrawerItemList {...props} />
      </View>
    </SafeAreaView>
  )
}
const DrawerTab = createDrawerNavigator();

function DrawerNavigator({ navigation }) {
  return (
    <DrawerTab.Navigator
      drawerContent={(props) => (<CustomDrawer {...props} />)}
      initialRouteName="TabOne"
      screenOptions={{
        backBehavior: 'history',
        drawerActiveBackgroundColor: '#fff',
        drawerActiveTintColor: Colors.actionColor,
        drawerPosition: 'right',
        drawerType: "slide",
        drawerStyle: {
          width: "100%",
        },
        headerStyle: {
          minHeight: 100,
        },
        drawerLabelStyle: {
          fontFamily: 'Jost-Medium',
          fontSize: 16
        },
        headerTitleStyle: [ScreenHeaderText.screenHeaderTitle],
        headerTitleAlign: 'center',
      }}>
      <DrawerTab.Screen
        name="TabOne"
        component={TabOneScreen}
        options={{
          title: 'Letravvi',
          header: ({ navigation }) => (
            <AppHeader
              rightIcon="menu"
              rightColor={Colors.actionColor}
              rightNav={() => navigation.openDrawer()}
              title="Letravvi"
            />
          )
        }}
      />
      <DrawerTab.Screen
        name="TabTwo"
        component={TabTwoScreen}
        options={{
          title: 'Contact us',
          header: ({ navigation }) => (
            <AppHeader
              rightIcon="menu"
              rightColor={Colors.actionColor}
              rightNav={() => navigation.openDrawer()}
              title="Contact us"
            />
          )
        }}
      />
    </DrawerTab.Navigator>
  );
}



