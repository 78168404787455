import * as React from 'react';
import { Text, View, Dimensions, ScrollView, TouchableOpacity, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as Linking from 'expo-linking'

import Containers from '../components/StyledContainers';
import { Content } from '../components/StyledText';
import Colors from '../constants/Colors';
import { SimpleLineIcons } from '@expo/vector-icons';



export default function TabOneScreen() {
    const paragraphs = [
        {
            id: 0,
            element:
                <Text style={Content.introParagraph}>
                    For some of us, travel is not merely utilitarian.
                    It is how we build our sense of place in the world,
                    it is how we escape the routine and the mundane to reconnect with parts of ourselves.
                    It is how we seek inspiration and wonder.
                </Text>
        },
        {
            id: 1,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        The impact of our travel choices can be more complicated, even when our motivations are noble.
                        We might contribute to economies and livelihoods,
                        but we also risk leaving the places we visit worse than we found them.
                    </Text>
                </View>
        },
        {
            id: 2,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        Based on the choices we make, and who else makes them at the same time we do, we
                        may displace residents, disrupt socio-economic structures,
                        and leave behind a carbon footprint that we cannot make up for, whatever our
                        subsequent offsetting attempts</Text>
                    <Text style={{ color: Colors.accentColor, paddingTop: 17 }} onPress={() => Linking.openURL("https://www.washingtonpost.com/climate-solutions/2019/12/12/whats-greenest-way-travel/")}> - On offsetting attempts</Text>
                </View>
        },
        {
            id: 3,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        Conscious as we are, it is also possible to get critical of just how inadequate and
                        ill-adjusted our individual choices and habits are, to address the negative
                        impacts that may result from travel.
                    </Text>
                </View>
        },
        {
            id: 30,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        Canceling a trip to a mass tourism destination hardly feels meaningful, in a context where millions of other travelers are unlikely to adjust their actions
                    </Text>
                    <Text style={{ color: Colors.accentColor, paddingTop: 17 }} onPress={() => Linking.openURL("https://www.dw.com/en/tourism-overwhelms-vanishing-venice/a-16364608")}> - On loving Venice to death</Text>
                </View>
        },
        {
            id: 4,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        In Travelling While Black, Nanjala Nyabola argues for the
                        importance of everyday activism.
                    </Text>
                </View>
        },
        {
            id: 40,
            element:
                <View>
                    <Text style={Content.introParagraph}>
                        Individual agency might accumulate into public discourse that pressures corporate and state actors into action.
                        Or it might at least keep us uncomfortable and non-complacent enough to keep searching for solutions.
                    </Text>
                </View>
        },
        {
            id: 5,
            element:
                <Text style={Content.introParagraph}>
                    Let’s travel vizuri (Swahili for well), is a call to consciousness and individual agency.
                    How do we travel well?
                </Text>
        },
        {
            id: 6,
            element:
                <Text style={Content.introParagraph}>
                    How do we make traveling choices that reduce our carbon footprint?
                    How do we offset such a footprint meaningfully?
                </Text>
        },
        {
            id: 8,
            element:
                <Text style={Content.introParagraph}>
                    How do we look beneath the surface so that we are not merely appropriating culture when we participate,
                    and so that we are not merely playing voyeurs when we witness those whose lives are different from our own?
                </Text>
        },
        {
            id: 9,
            element:
                <Text style={Content.introParagraph}>
                    How do we come away from our travel experiences with a greater and deeper understanding of our humanity,
                    our connectedness, and our equality? And what do we do with the privilege of possesing such knowledge?
                </Text>
        }
    ]


    const smallDevice = Dimensions.get('window').width < 374


    return (
        <SafeAreaView style={Containers.horizontalScreenFrame}>
            {smallDevice ? (
                <View style={{ flex: 1 }}>
                    <View style={[Containers.contentCard, {
                        height: (0.6 * Dimensions.get('window').height),
                        position: 'absolute',
                        top: (0.1 * Dimensions.get('window').height),
                    }]}>
                        <ScrollView
                            scrollThrottleEvent={16}
                            showsVerticalScrollIndicator={false}
                            snapToInterval={(0.6 * Dimensions.get('window').height)}
                            snapToAlignment={'center'}
                            decelerationRate='normal'
                            pagingEnabled={true}

                        >
                            {paragraphs.map((paragraph) => (
                                <View key={paragraph.id} style={{ height: (0.6 * Dimensions.get('window').height), justifyContent: 'center', paddingHorizontal: 16, fontSize: 17 }}>
                                    {paragraph.element}
                                </View>
                            ))}
                        </ScrollView>
                    </View>
                </View>
            ) :
                (
                    <>
                        <View style={{ flex: 0.6 }}>
                            <Image
                                source={require('../assets/images/booksandtravel.png')}
                                style={{ height: '100%', width: '100%', resizeMode: 'cover' }}
                            />
                        </View>
                        <View style={{ flex: 0.4 }}>
                            <View style={[Containers.contentCard, {
                                height: (0.6 * Dimensions.get('window').height),
                                position: 'absolute',
                                left: (-0.2 * Dimensions.get('window').width),
                                top: (0.2 * Dimensions.get('window').height),
                            }]}>
                                <ScrollView
                                    scrollThrottleEvent={16}
                                    showsVerticalScrollIndicator={false}
                                    snapToInterval={(0.6 * Dimensions.get('window').height)}
                                    snapToAlignment={'center'}
                                    decelerationRate='normal'
                                    pagingEnabled={true}

                                >
                                    {paragraphs.map((paragraph) => (
                                        <View key={paragraph.id} style={{ height: (0.6 * Dimensions.get('window').height), justifyContent: 'center', paddingHorizontal: 16, fontSize: 17 }}>
                                            {paragraph.element}
                                        </View>
                                    ))}
                                </ScrollView>
                            </View>
                        </View>
                    </>
                )}
        </SafeAreaView>
    );
}




