import React, { useState} from "react";
import {
    View,
    Text,
    TextInput,
    StyleSheet,
    TouchableOpacity
} from "react-native";
import Colors from "../constants/Colors";
import _ from "lodash";
import { AppText, Content } from "./StyledText";
import Containers from "./StyledContainers";


export function AppTextInput(props) {
    const error = props.error;

    const [focused, setFocus] = useState(false);

    const changeFocus = () => {
        setFocus(true);
    };

    const checkEditStatus = () => {
        if (typeof error !== "undefined" && error.length !== 0) {
            props.onErrorCorrect();
        }
        return;
    };

    const activeStyle = focused
        ? Containers.activebordercolor
        : Containers.inactivebordercolor;

    return (
        <>
            <AppText>{props.label}</AppText>
            <TextInput
                style={[
                    Containers.textInputStyle,
                    typeof error !== "undefined" && error.length !== 0
                        ? Containers.errorbordercolor
                        : activeStyle,
                    { ...props.multilineStyle }
                ]}
                selectionColor={Colors.accentColor}
                placeholderTextColor={Colors.secondaryMetaColor}
                onFocus={() => changeFocus()}
                onChange={checkEditStatus}
                {...props}
            />
            <View style={{ marginRight: 12 }}>
                <Text style={Content.metaTagging}>{props.fieldDescription}</Text>
            </View>
            <View>
                {typeof error !== "undefined" && error.length !== 0 && (
                    <Text style={Content.errorText}>{error}</Text>
                )}
            </View>
        </>
    );
}

export const ActionButton = (props) => {
    return (
        <TouchableOpacity style={[styles.action, props.style]} onPress={props.onPress}>
            <Text style={styles.actionButtonText}>{props.title}</Text>
        </TouchableOpacity>
    )
}


const styles = StyleSheet.create({
    action: {
        borderWidth: 2,
        borderColor: '#c86c74',
        paddingVertical: 7,
        marginVertical: 27,
        width: '67%'
    },
    actionButtonText: {
        fontFamily: "Jost-Medium",
        fontSize: 15,
        lineHeight: 18,
        letterSpacing: 0.15,
        textAlign: "center",
        color: '#c86c74',
        textTransform: 'uppercase',
    }
})