import React from 'react'
import { View, Pressable, Text } from 'react-native'
import { SimpleLineIcons } from '@expo/vector-icons';
import { ScreenHeaderText } from './StyledText';
import Containers from './StyledContainers';
import { useSafeAreaInsets, SafeAreaView } from 'react-native-safe-area-context';

export function LeftButton(props) {
    return (
        <Pressable onPress={props.leftNav}>
            <View>
                {props.leftIcon && (
                    <SimpleLineIcons name={props.leftIcon} color={props.leftColor || "black"} size={20} />
                )}
                {props.leftLabel && (
                    <Text style={[ScreenHeaderText.headerButton, { marginLeft: 7 }]}>
                        {props.leftLabel}
                    </Text>
                )}
            </View>
        </Pressable>
    );
}

export function RightButton(props) {
    return (
        <Pressable onPress={props.rightNav} disabled={props.disabled}>
            <View>
                {props.rightLabel && (
                    <Text style={[ScreenHeaderText.headerButton, { marginRight: 7 }]}>
                        {props.rightLabel}
                    </Text>
                )}
                {props.rightIcon && (
                    <SimpleLineIcons name={props.rightIcon} color={props.rightColor || "black"} size={20} />
                )}
            </View>
        </Pressable>
    );
}


export function AppHeader(props) {

    const insets = useSafeAreaInsets()

    return (
        <View style={[Containers.appHeader, { paddingTop: insets.top + 14, paddingHorizontal: 20 }]}>
            <>
                <View>
                    <LeftButton
                        leftNav={props.leftNav}
                        leftIcon={props.leftIcon}
                        leftColor={props.leftColor}
                        leftLabel={props.leftLabel}
                    />
                </View>
                <View>
                    {props.title && (
                        <Text style={ScreenHeaderText.screenHeaderTitle}>
                            {props.title}
                        </Text>
                    )}
                </View>
                <View>
                    <RightButton
                        rightNav={props.rightNav}
                        rightIcon={props.rightIcon}
                        rightColor={props.rightColor}
                        rightLabel={props.rightLabel}
                    />
                </View>
            </>
        </View>
    );
}