import * as React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors'


export const Content = StyleSheet.create({
  introParagraph: {
    fontSize: 16,
    color: Colors.textColor,
    fontFamily: 'Jost-Regular',
    lineHeight:36,
  },
    paragraph: {
    fontSize: 15,
    color: Colors.textColor,
    fontFamily: 'Jost-Regular',
  },
  boldParagraph: {
    fontSize: 15,
    color: Colors.textColor,
    fontFamily: 'Jost-Medium',
  },
  paragraphLink: {
    fontSize: 15,
    color: Colors.accentColor,
    fontFamily: 'Jost-Medium',
  },
  sectionHeading: {
    fontSize: 17,
    fontFamily: 'Jost-Medium',
    color: Colors.textColor,
  },
  subHeading: {
    fontSize: 16,
    fontFamily: 'Jost-Medium',
    color: Colors.textColor,
  },
  sectionHeadingLink: {
    fontSize: 17,
    fontFamily: 'Jost-Medium',
    color: Colors.accentColor,
  },
  largeSectionText: {
    fontSize: 17,
    fontFamily: 'Jost-Regular',
    color: Colors.textColor,
  },
  feedbackText: {
    fontSize: 17,
    fontFamily: 'Jost-Regular',
    padding:3,
    color: Colors.noticeText,
    backgroundColor: Colors.noticeBackground,
  },
  errorText: {
    fontSize: 14,
    fontFamily: 'Jost-Regular',
    color: Colors.errorText,
  },
  sectionTagging: {
    fontFamily: "Jost-Regular",
    fontSize: 12,
    letterSpacing: 0.8,
    color: Colors.accentColor,
    textTransform: 'uppercase'
  },
  mainScreenHeading: {
    fontFamily: "Jost-Medium",
    fontSize: 19,
    fontStyle: "normal",
    letterSpacing: 0.1,
    color: Colors.textColor,
  },
  metaTagging: {
    fontFamily: "Jost-Regular",
    fontSize: 12,
    letterSpacing: 0.25,
    color: Colors.secondaryMetaColor,
  },
  metaTaggingUpper: {
    fontFamily: "Jost-Regular",
    fontSize: 12,
    letterSpacing: 0.25,
    textTransform: 'uppercase',
    color: Colors.secondaryMetaColor,
  }
})


export function AppText(props) {
  return (
    <Text style={[Content.paragraph, props.style]}>{props.children}</Text>
  )
}




export function AppLink(props) {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <Text style={[{ color: Colors.accentColor }, props.style]}>{props.children}</Text>
    </TouchableOpacity>
  )
}


export function ActionLink(props) {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <Text style={[{ color: Colors.actionColor }, props.style]}>{props.children}</Text>
    </TouchableOpacity>
  )
}


export const ScreenHeaderText = StyleSheet.create({
  screenHeaderTitle: {
    fontSize: 16,
    lineHeight: 26,
    fontFamily: 'Jost-SemiBold',
    color: '#000',
  },
  dataPropsTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Jost-Medium',
    color: '#000000',
  },
  headerButton: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.textColor,
    fontFamily: 'Jost-Medium',
  }
})



export const ContentHeadlineText = StyleSheet.create({
  listedHeadline: {
    fontSize: 19,
    lineHeight: 26,
    fontFamily: 'Jost-Medium',
    color: '#000000',
  },
})



export const HelperText = StyleSheet.create({
  iconLabel: {
    fontSize: 11,
    fontFamily: 'Jost-Regular',
  }
})
