import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyC8BlQGo07oUk6QqzL0kKA5tVR7g5t-SuM",
    authDomain: "letravvi.firebaseapp.com",
    projectId: "letravvi",
    storageBucket: "letravvi.appspot.com",
    messagingSenderId: "57619435101",
    appId: "1:57619435101:web:5b494141d4917ea419c7b4",
    measurementId: "G-RCRLDLBJLL"
};

initializeApp(firebaseConfig)

export const fireStore = getFirestore();

