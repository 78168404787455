const tintColor = '#c86c74';

export default {
  tintColor,
  tint: '#c86c74',
  tabIconDefault: '#000',
  tabIconSelected: '#c86c74',
  tabBar: '#ffffff',
  errorBackground: 'red',
  errorText: '#BA4751',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground:'#E3B5B9',
  noticeText: '#fff',
  textColor: '#000000',
  actionColor: '#c86c74',
  accentColor: '#0070c9',
  metaColor: '#b7864e',
  secondaryMetaColor: '#727272',
  tertiaryMetaColor: '#cccccc',
};
